import "./App.css";

function App() {
  return (
    <div className="App">
      welcome to overflow stack developer
      <br />
      the website is under construction
    </div>
  );
}

export default App;
